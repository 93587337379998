import { appConfig } from './app/app.config';
import { environment } from './environments/environment';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { StandaloneApplication } from '@rxap/ngx-bootstrap';
import { AppComponent } from './app/app.component';
import { UnregisterServiceWorker } from '@rxap/service-worker';
import { OpenApiInit } from '@rxap/open-api';
import { SentryInit } from '@rxap/ngx-sentry';
import { defineCustomElements } from 'xml-viewer-component/dist/loader';

const application = new StandaloneApplication(
  environment,
  AppComponent,
  appConfig,
);
application.importProvidersFrom(
  LoggerModule.forRoot({
    serverLoggingUrl: '/api/logs',
    level: NgxLoggerLevel.DEBUG,
    serverLogLevel: NgxLoggerLevel.ERROR,
  })
);
application.before(() => UnregisterServiceWorker(environment));
application.before(() => OpenApiInit(environment, {load: true}));
application.before(() => SentryInit(environment));
application.bootstrap().catch((err) => console.error(err));

defineCustomElements(window);
