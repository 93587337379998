<button [matMenuTriggerFor]="menu" mat-icon-button>
  <mat-icon svgIcon="cog"></mat-icon>
</button>

<mat-menu #menu="matMenu">
  <button (click)="themeService.toggleDarkTheme()" mat-menu-item>
    @if (themeService.darkMode()) {
      <mat-icon svgIcon="brightness-2"></mat-icon>
    } @else {
      <mat-icon svgIcon="brightness-5"></mat-icon>
    }
    <span i18n>Mode</span>
  </button>
  <button [matMenuTriggerFor]="themeMenu" mat-menu-item>
    <mat-icon svgIcon="compare"></mat-icon>
    <span i18n>Theme</span>
  </button>
  @for (item of customItems(); track item.label) {
    <button (click)="clickItem(item)" mat-menu-item>
      <mat-icon *ngIf="item.icon as icon" [rxapIcon]="icon"></mat-icon>
      <span>{{ item.label }}</span>
    </button>
  }
  @for (item of customItemComponents(); track item) {
    <ng-template [cdkPortalOutlet]="item"></ng-template>
  }
</mat-menu>

<mat-menu #themeMenu="matMenu" xPosition="before">
  <button [matMenuTriggerFor]="themeDensityMenu" mat-menu-item>
    <mat-icon svgIcon="move-resize"></mat-icon>
    <span i18n>Density</span>
  </button>
  @if (availableTypographies?.length) {
    <button [matMenuTriggerFor]="themeFontMenu" mat-menu-item>
      <mat-icon svgIcon="format-font"></mat-icon>
      <span i18n>Font</span>
    </button>
  }
  @if (availableThemes?.length) {
    <button [matMenuTriggerFor]="themePresetMenu" mat-menu-item>
      <mat-icon svgIcon="shape-outline"></mat-icon>
      <span i18n>Preset</span>
    </button>
  }
</mat-menu>

<mat-menu #themeDensityMenu="matMenu" xPosition="before">
  <button (click)="setDensity(0)" (mouseenter)="previewDensity(0)" (mouseleave)="restoreDensity()" mat-menu-item>
    <mat-icon svgIcon="size-l"></mat-icon>
    <span i18n>Normal</span>
  </button>
  <button (click)="setDensity(-1)" (mouseenter)="previewDensity(-1)" (mouseleave)="restoreDensity()" mat-menu-item>
    <mat-icon svgIcon="size-m"></mat-icon>
    <span i18n>Dense</span>
  </button>
  <button (click)="setDensity(-2)" (mouseenter)="previewDensity(-2)" (mouseleave)="restoreDensity()" mat-menu-item>
    <mat-icon svgIcon="size-s"></mat-icon>
    <span i18n>Very Dense</span>
  </button>
  <button (click)="setDensity(-3)" (mouseenter)="previewDensity(-3)" (mouseleave)="restoreDensity()" mat-menu-item>
    <mat-icon svgIcon="size-xs"></mat-icon>
    <span i18n>Extreme Dense</span>
  </button>
</mat-menu>
<mat-menu #themeFontMenu="matMenu" xPosition="before">
  @for (typographyName of availableTypographies ?? []; track typographyName) {
    <button (click)="setTypography(typographyName)"
            (mouseenter)="previewTypography(typographyName)"
            (mouseleave)="restoreTypography()"
            mat-menu-item>
      {{ typographyName }}
    </button>
  }
</mat-menu>

<mat-menu #themePresetMenu="matMenu" xPosition="before">
  @for (themeName of availableThemes ?? []; track themeName) {
    <button (click)="setTheme(themeName)"
            (mouseenter)="previewTheme(themeName)"
            (mouseleave)="restoreTheme()"
            mat-menu-item>
      {{ themeName }}
    </button>
  }
</mat-menu>
