import {
  computed,
  inject,
  Injectable,
  signal,
} from '@angular/core';
import { ConfigService } from '@rxap/config';
import { EUROGARD_LOGO_CONFIG } from './tokens';

@Injectable()
export class LogoService {

  private readonly config = inject(ConfigService);
  public readonly logo = signal(
    inject(EUROGARD_LOGO_CONFIG, { optional: true }) ??
    this.config.get('logo', {
      src: 'logo.png',
      width: 192,
    }),
  );

  public readonly src = computed(() => this.logo().src);
  public readonly width = computed(() => this.logo().width);
  public readonly height = computed(() => this.logo().height);

}
