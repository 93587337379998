@if (hasApps()) {
  <div class="flex flex-row items-center gap-8">

    @if (isOpen()) {
      <div class="flex flex-row items-center gap-6 h-10">

        @for (app of appList(); track app.label) {
          @if (app.href) {
            <a [href]="app.href" [target]="app.target ?? '_self'" class="app-navigation-button" mat-stroked-button>
              <span class="flex flex-row items-center gap-4">
                @if (app.icon) {
                  <mat-icon [rxapIcon]="app.icon"></mat-icon>
                }
                @if (app.image) {
                  <img [alt]="app.label" [ngSrc]="app.image" height="40" width="40">
                }
                <span class="label grow-0">{{ app.label }}</span>
              </span>
            </a>
          } @else if (app.routerLink) {
            <a class="app-navigation-button" mat-stroked-button [routerLink]="app.routerLink">
              <span class="flex flex-row items-center gap-4">
                @if (app.icon) {
                  <mat-icon [rxapIcon]="app.icon"></mat-icon>
                }
                @if (app.image) {
                  <img [alt]="app.label" [ngSrc]="app.image" height="40" width="40">
                }
                <span class="label grow-0">{{ app.label }}</span>
              </span>
            </a>
          } @else {
            <button class="app-navigation-button" mat-stroked-button [disabled]="true">
              <span class="flex flex-row items-center gap-4">
                @if (app.icon) {
                  <mat-icon [rxapIcon]="app.icon"></mat-icon>
                }
                @if (app.image) {
                  <img [alt]="app.label" [ngSrc]="app.image" height="40" width="40">
                }
                <span class="label grow-0">{{ app.label }}</span>
              </span>
            </button>
          }
        }

      </div>
    }

    <button (click)="toggle()" mat-icon-button>
      <mat-icon svgIcon="apps"></mat-icon>
    </button>

  </div>
}
