import { ThemeDensity } from 'eurogard-utilities';
import { Observable } from 'rxjs';

export function ObserveCurrentThemeDensity(): Observable<ThemeDensity> {
  return new Observable((subscriber) => {
    const emitCurrentDensity = () => {
      const classList = document.body.classList;
      const matchingClasses = Array.from(classList).filter((className) =>
        /density-\d/.test(className),
      );
      if (matchingClasses.length > 0) {
        const match = matchingClasses[0].match(/density-(\d)/);
        if (match) {
          subscriber.next(Number(match[1]) * -1 as ThemeDensity);
        }
      } else {
        subscriber.next(0);
      }
    };

    emitCurrentDensity();

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
          emitCurrentDensity();
        }
      });
    });

    subscriber.add(() => observer.disconnect());

    observer.observe(document.body, {
      attributes: true,
      attributeFilter: ['class'],
    });
  });
}
