<button [matMenuTriggerFor]="menu" mat-icon-button>
  <mat-icon svgIcon="account-circle"></mat-icon>
</button>

<mat-menu #menu="matMenu" [yPosition]="'below'" class="!max-w-none">
  @if (this.username(); as username) {
  <button mat-menu-item>
    <span class="flex flex-row gap-2">
      <mat-icon svgIcon="account"></mat-icon>
      <span>{{username}}</span>
    </span>
  </button>
  }
  <button (click)="logout()" mat-menu-item>
    <span class="flex flex-row gap-2">
      <mat-icon svgIcon="logout"></mat-icon>
      <span i18n>Logout</span>
    </span>
  </button>
</mat-menu>
