import { Environment } from '@rxap/environment';

export const environment: Environment = {
  name: 'production',
  production: true,
  app: 'settings',
  serviceWorker: true,
  sentry: {
    enabled: true,
    debug: false,
    dsn: 'https://6f5715c995d246e5b1c12e353098a26b@sentry.eurogard.cloud/3',
  },
};
