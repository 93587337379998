import { ComponentType } from '@angular/cdk/portal';
import { Provider } from '@angular/core';
import { DataSource } from '@rxap/pattern';
import { Constructor } from '@rxap/utilities';
import { ExternalAppsService } from './external-apps.service';
import { FooterService } from './footer.service';
import { HeaderService } from './header.service';
import { LayoutService } from './layout.service';
import { LogoService } from './logo.service';
import { NavigationService } from './navigation.service';
import {
  EUROGARD_EXTERNAL_APP,
  EUROGARD_FOOTER_COMPONENT,
  EUROGARD_HEADER_COMPONENT,
  EUROGARD_NAVIGATION_CONFIG,
  EUROGARD_NAVIGATION_CONFIG_INSERTS,
  EUROGARD_RELEASE_INFO_MODULE,
  EUROGARD_SETTINGS_MENU_ITEM,
  EUROGARD_SETTINGS_MENU_ITEM_COMPONENT,
  EUROGARD_USER_PROFILE_DATA_SOURCE,
} from './tokens';
import {
  ExternalApp,
  ReleaseInfoModule,
  SettingsMenuItem,
  SettingsMenuItemComponent,
} from './types';
import { NavigationWithInserts } from 'angular-components/navigation/navigation-item';
import { DefaultHeaderComponent } from 'angular-components/layout/default-header/default-header.component';

export function provideLayout(...additionalProviders: Provider[]): Provider[] {
  return [
    LayoutService,
    LogoService,
    HeaderService,
    FooterService,
    ...additionalProviders,
  ];
}

export function provideExternalApps(...apps: ExternalApp[]): Provider[] {
  return [
    ExternalAppsService,
    ...apps.map(app => (
      {
        provide: EUROGARD_EXTERNAL_APP,
        useValue: app,
        multi: true,
      }
    ))
  ];
}

export function withNavigationConfig(
  config: NavigationWithInserts | (() => NavigationWithInserts),
): Provider[] {
  return [
    NavigationService,
    {
      provide: EUROGARD_NAVIGATION_CONFIG,
      useValue: config,
    },
  ];
}

export function withNavigationInserts(inserts: Record<string, NavigationWithInserts>): Provider[] {
  return [{
    provide: EUROGARD_NAVIGATION_CONFIG_INSERTS,
    useValue: inserts,
  }];
}

export function withSettingsMenuItems(...items: Array<SettingsMenuItemComponent | SettingsMenuItem>): Provider[] {
  return [
    ...items.filter((item): item is SettingsMenuItemComponent => typeof item === 'function').map(component => (
      {
        provide: EUROGARD_SETTINGS_MENU_ITEM_COMPONENT,
        useValue: component,
        multi: true,
      }
    )),
    ...items.filter((item): item is SettingsMenuItem => typeof item !== 'function').map(item => (
      {
        provide: EUROGARD_SETTINGS_MENU_ITEM,
        useValue: item,
        multi: true,
      }
    )),
  ];
}

export function withReleaseInfoModules(...module: ReleaseInfoModule[]): Provider[] {
  return module.map(item => (
    {
      provide: EUROGARD_RELEASE_INFO_MODULE,
      useValue: item,
      multi: true,
    }
  ));
}

export function withHeaderComponents(components: Array<ComponentType<unknown>>): Provider[] {
  return components.map(component => (
    {
      provide: EUROGARD_HEADER_COMPONENT,
      useValue: component,
    }
  ));
}

export function withFooterComponents(components: Array<ComponentType<unknown>>): Provider[] {
  return components.map(component => (
    {
      provide: EUROGARD_FOOTER_COMPONENT,
      useValue: component,
    }
  ));
}

export function withDefaultHeaderComponent(): Provider {
  return {
    provide: EUROGARD_HEADER_COMPONENT,
    useValue: DefaultHeaderComponent,
  };
}

export function withUserProfileDataSource(dataSource: Constructor<DataSource>, useClass = false): Provider {
  if (useClass) {
    return {
      provide: EUROGARD_USER_PROFILE_DATA_SOURCE,
      useClass: dataSource
    };
  } else {
    return {
      provide: EUROGARD_USER_PROFILE_DATA_SOURCE,
      useExisting: dataSource
    };
  }
}
