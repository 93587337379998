import { Route } from '@angular/router';
import { STATUS_CHECK_ROUTE } from '@rxap/ngx-status-check';

export const appRoutes: Route[] = [
  STATUS_CHECK_ROUTE,
  {
    path: 'error',
    loadChildren: () => import('../feature/error/routes'),
  },
  {
    path: '',
    loadChildren: () => import('./layout.routes'),
  },
  {
    path: '**',
    redirectTo: '',
  },
];

export default appRoutes;
