import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { PubSubService } from '@rxap/ngx-pub-sub';
import { EXTRACT_USERNAME_FROM_PROFILE } from 'angular-services/layout/tokens';
import { ExtractUsernameFromProfileFn } from 'angular-services/layout/types';
import { RXAP_TOPICS } from 'eurogard-utilities';
import { MatIconButton } from '@angular/material/button';

@Component({
  selector: 'eurogard-user-profile-icon',
  templateUrl: './user-profile-icon.component.html',
  styleUrls: [ './user-profile-icon.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatMenuModule,
    MatIconModule,
    MatIconButton,
  ],
})
export class UserProfileIconComponent {

  private readonly extractUsernameFromProfile: ExtractUsernameFromProfileFn = inject(EXTRACT_USERNAME_FROM_PROFILE);
  private readonly pubSubService = inject(PubSubService);

  public readonly profile = input.required();

  public readonly username = computed(() => {
    const profile = this.profile();
    if (profile) {
      return this.extractUsernameFromProfile(profile);
    }
    return null;
  });

  public logout() {
    this.pubSubService.publish(RXAP_TOPICS.authentication.logout);
  }


}
