import {
  CdkPortalOutlet,
  ComponentPortal,
} from '@angular/cdk/portal';
import { NgIf } from '@angular/common';
import {
  Component,
  inject,
  Injector,
  isDevMode,
  runInInjectionContext,
  signal,
} from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import {
  MatMenu,
  MatMenuItem,
  MatMenuTrigger,
} from '@angular/material/menu';
import { IconDirective } from '@rxap/material-directives/icon';
import {
  coerceArray,
} from '@rxap/utilities';
import { EUROGARD_SETTINGS_MENU_ITEM, EUROGARD_SETTINGS_MENU_ITEM_COMPONENT } from 'angular-services/layout/tokens';
import { SettingsMenuItem } from 'angular-services/layout/types';
import { ThemeDensity } from 'eurogard-utilities';
import { ThemeService } from 'angular-services/theme/theme.service';

@Component({
  selector: 'eurogard-settings-button',
  standalone: true,
  templateUrl: './settings-button.component.html',
  styleUrls: [ './settings-button.component.scss' ],
  imports: [
    MatIconButton,
    MatIcon,
    MatMenu,
    MatMenuTrigger,
    MatMenuItem,
    CdkPortalOutlet,
    IconDirective,
    NgIf,
  ],
})
export class SettingsButtonComponent {

  public isDevMode = isDevMode();

  public readonly themeService = inject(ThemeService);
  private readonly injector    = inject(Injector);

  customItemComponents = signal(
    coerceArray(inject(EUROGARD_SETTINGS_MENU_ITEM_COMPONENT, { optional: true }))
      .map(item => new ComponentPortal(item, null, this.injector)),
  );

  customItems = signal(coerceArray(inject(EUROGARD_SETTINGS_MENU_ITEM, { optional: true })));

  private savePreviewDensityValue = false;
  private currentDensityValue: ThemeDensity | null = null;

  private savePreviewTypographyValue = false;
  private currentTypographyValue: string | null = null;

  public readonly availableTypographies = this.themeService.getAvailableTypographies();
  private savePreviewThemeValue = false;

  public readonly availableThemes = this.themeService.getAvailableThemes();
  private currentThemeValue: string | null = null;

  previewDensity(density: ThemeDensity) {
    this.themeService.applyDensity(density);
  }

  restoreDensity() {
    this.themeService.applyDensity(this.themeService.density());
  }

  setDensity(density: ThemeDensity) {
    this.themeService.setDensity(density);
  }

  previewTypography(typography: string) {
    this.themeService.applyTypography(typography);
  }

  restoreTypography() {
    this.themeService.applyTypography(this.themeService.typography());
  }

  setTypography(typography: string) {
    this.themeService.setTypography(typography);
  }

  previewTheme(theme: string) {
    this.themeService.applyTheme(theme);
  }

  restoreTheme() {
    this.themeService.applyTheme(this.themeService.themeName());
  }

  setTheme(theme: string) {
    this.themeService.setTheme(theme);
  }

  clickItem(item: SettingsMenuItem) {
    runInInjectionContext(this.injector, () => item.action());
  }
}
