<div class="w-full flex flex-row gap-x-2 justify-between items-center">
  @if (!collapsable()) {
    <eurogard-sidenav-toggle-button></eurogard-sidenav-toggle-button>
  }
  <div class="grow">
    <ng-content></ng-content>
  </div>
  <eurogard-apps-button class="grow-0"></eurogard-apps-button>
  <eurogard-settings-button class="grow-0"></eurogard-settings-button>
  @if (this.profile(); as profile) {
    <eurogard-user-profile-icon [profile]="profile" class="grow-0"></eurogard-user-profile-icon>
  }
</div>
